import Head from 'next/head';
import Link from 'next/link';
import { useTranslation } from 'next-i18next';
import { serverSideTranslations } from 'next-i18next/serverSideTranslations';

// Components
import Button from '../components/atoms/Button/Button';

// Images
import Tennis from '../public/images/tennis.svg';

import styles from '../styles/pages/Page404.module.scss';

function Page404() {
  const { t } = useTranslation();

  return (
    <>
      <Head>
        <title>
          404
        </title>
      </Head>
      <div className={`${styles.page} flex center d-col`}>
        <Tennis />
        <h2 className="m-t-30 fs-24 fw-500 text-c-primary">{t('page.error.notFound')}</h2>

        <Link href="/" passHref>
          <Button classNames="m-t-40" text="uppercase">{t('page.error.backHome')}</Button>
        </Link>
      </div>
    </>
  );
}

export async function getStaticProps({ locale }) {
  return {
    props: {
      ...(await serverSideTranslations(
        locale || 'fr',
        ['common'],
      )),
    },
  };
}

export default Page404;
